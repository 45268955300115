import { ref, computed, watchEffect, type Ref } from "vue";
import isEmpty from "lodash/isEmpty";
import type {
  CallOutPayload,
  ContactTelephones
} from "@/models/communicationLogs";
import type { Nullish } from "@/models/common";
import type {
  ApplicationWithContactData,
  ContactProfile
} from "@/models/applications";
import { CalleeType } from "@/enums/communicationLogs";
import { formatUserFullName, getInitials } from "@/helpers/formatting";
import { getContactsWithCalleeType } from "@/helpers/communication";
import { useCommunicationStore } from "@/stores/communication";
import { useNotification } from "@/hooks/notifications";
import { useI18n } from "vue-i18n";
import { USER_BADGE_COLORS } from "@/helpers/constants";

const stripCallingCode = (number: string | Nullish) => {
  const value = number || "";
  if (value.includes("+1")) {
    return value.replace("+1", "").trim();
  }
  return value;
};

const LOCAL_STORAGE_SELECTED_TELEPHONES_KEY = "telephones";
const selectedTelephones = ref<Record<string, string>>({});
const defaultTelephones = ref<Record<string, string>>({});
const contacts = ref<ContactTelephones[]>([]);
export const useContacts = (deal?: Ref<ApplicationWithContactData>) => {
  const { showMessage } = useNotification();
  const { t } = useI18n();

  const hasBusinessRelationshipOwners = computed(
    () => !isEmpty(deal?.value?.relationships)
  );

  const hasBusinessPhoneNumbers = computed(
    () => !isEmpty(deal?.value?.phone_numbers)
  );

  const telephoneNumber = computed(() => {
    return deal?.value?.id
      ? selectedTelephones.value?.[deal.value.id] ||
          (deal.value.personal_information?.telephone?.toString() ?? "")
      : "";
  });

  const primaryBusinessOwner = computed(
    () => deal?.value?.relationships?.[0]?.profile
  );

  const telephoneLink = computed(() => {
    if (hasBusinessRelationshipOwners.value) {
      return primaryBusinessOwner.value?.phone_numbers?.[0]?.phone_number ?? "";
    }
    if (hasBusinessPhoneNumbers.value) {
      const businessPrimaryNumber =
        deal?.value?.phone_numbers?.[0]?.phone_number;
      return businessPrimaryNumber ?? "";
    }
    return telephoneNumber.value;
  });

  const contactName = computed(() => {
    const personalInformation = deal?.value?.personal_information;
    const businessInformation = deal?.value?.business;
    if (
      personalInformation &&
      [
        personalInformation.telephone,
        ...(personalInformation?.additional_telephones || [])
      ].includes(telephoneNumber.value)
    ) {
      return formatUserFullName(personalInformation);
    }
    if (businessInformation?.other_owners?.length) {
      const otherOwner = businessInformation.other_owners.find(
        (owner) => owner.telephone === telephoneNumber.value
      );
      if (otherOwner) {
        return formatUserFullName(otherOwner);
      }
    }
    if (hasBusinessRelationshipOwners.value) {
      return primaryBusinessOwner.value?.name || "";
    }
    const businessName = deal?.value?.name;
    return businessInformation?.business_legal_name || businessName || "";
  });

  selectedTelephones.value = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_SELECTED_TELEPHONES_KEY) || "{}"
  );

  const setTelephoneForApplication = (id: string, telephone: string) => {
    selectedTelephones.value = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_SELECTED_TELEPHONES_KEY) || "{}"
    );
    selectedTelephones.value[id] = telephone;
  };

  const setContacts = (id: string, contactsInfo: ContactTelephones[]) => {
    contacts.value = contactsInfo.map((attr, index) => {
      const businessAdditionalTelephones = attr?.phone_numbers
        ?.slice(1)
        .map((user) => user.phone_number || "");

      const telephone =
        attr?.phone_numbers?.[0].phone_number || attr?.telephone;
      const calleeType =
        attr.callee_type ||
        (attr?.is_a_business_contact
          ? CalleeType.BusinessProfile
          : CalleeType.IndividualProfile);
      return {
        id: attr?.id,
        business_legal_name: attr?.business_legal_name,
        telephones: attr?.telephones,
        is_a_business_contact: Boolean(attr?.business_legal_name),
        first_name: attr?.first_name || attr?.name || t("COMMON.UNKNOWN"),
        last_name:
          attr?.last_name ||
          (attr?.is_a_business_contact || index === 0
            ? t("COMMON.COMPANY")
            : t("COMMON.OWNER")),
        telephone: telephone || "",
        additional_telephones:
          attr?.additional_telephones || businessAdditionalTelephones,
        callee_type: calleeType,
        email_address: attr?.email_address
      };
    });

    const defaultTelephone = contactsInfo[0]?.telephones?.[0] || "";

    if (!contactsInfo[1]) {
      defaultTelephones.value[id] = defaultTelephone;
      return;
    }

    const { telephone, phone_numbers } = contactsInfo[1];

    if (isEmpty(selectedTelephones.value[id]) && telephone) {
      defaultTelephones.value[id] = telephone;
    } else if (phone_numbers?.[0]?.phone_number) {
      defaultTelephones.value[id] = phone_numbers[0].phone_number;
    } else {
      defaultTelephones.value[id] = defaultTelephone;
    }
  };

  const getCallOutPayload = (
    phoneNumber: string
  ): CallOutPayload | undefined => {
    const contact = contacts.value.find((contact) => {
      const allNumbers = [
        contact.telephone,
        ...(contact.telephones ?? []),
        ...(contact.additional_telephones ?? [])
      ];
      for (let i = 0; i < allNumbers.length; i++) {
        if (stripCallingCode(allNumbers[i]) === stripCallingCode(phoneNumber)) {
          return true;
        }
      }
      return false;
    });
    if (!contact) {
      return undefined;
    }
    return {
      callee_id: contact.id,
      callee_type: contact.callee_type,
      to: phoneNumber.replace(/[\s()-]/g, "")
    };
  };

  const mapContactsInfo = (
    deal: ApplicationWithContactData | undefined
  ): ContactTelephones[] => {
    if (!deal) return [];
    if (deal?.personal_information) {
      return getContactsWithCalleeType({
        business: deal.business,
        owners: [
          deal.personal_information,
          ...(deal.business?.other_owners || [])
        ]
      });
    }
    return [
      {
        business_legal_name: deal.name,
        id: deal.id,
        is_a_business_contact: true,
        telephones:
          deal?.phone_numbers?.map((business) => business.phone_number) || []
      },
      ...(deal?.relationships?.reduce((acc: ContactProfile[], entity) => {
        if (entity.type === "owner") {
          acc.push(entity.profile);
        }
        return acc;
      }, []) || [])
    ] as ContactTelephones[];
  };

  const callClient = async (event: MouseEvent) => {
    if (!deal?.value) {
      return;
    }
    const { callOut } = useCommunicationStore();

    const contactsInfo = mapContactsInfo(deal.value);
    setContacts(deal?.value?.id, contactsInfo);
    const callOutPayload = getCallOutPayload(
      telephoneNumber.value || telephoneLink.value
    );
    if (!callOutPayload) {
      return;
    }
    try {
      const connectionLog = await callOut(deal?.value?.id, callOutPayload);
      return { connectionLog, event };
    } catch (e) {
      showMessage(t("COMMON.ERROR_OCCURRED", "error"));
    }
  };

  watchEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_SELECTED_TELEPHONES_KEY,
      JSON.stringify(selectedTelephones.value)
    );
  });

  return {
    contacts,
    hasBusinessRelationshipOwners,
    hasBusinessPhoneNumbers,
    telephoneNumber,
    primaryBusinessOwner,
    telephoneLink,
    contactName,
    selectedTelephones,
    defaultTelephones,
    setTelephoneForApplication,
    setContacts,
    getCallOutPayload,
    callClient
  };
};

export const useContactColors = (userName: Ref<string>) => {
  const userInitials = computed(() => getInitials(userName.value));

  const themeColor = computed(() => {
    let charCodeSum = 0;
    if (userInitials.value) {
      for (const char of userInitials.value.slice(0, 2)) {
        charCodeSum += char.charCodeAt(0);
      }
    }
    const colorIndex = charCodeSum % Object.keys(USER_BADGE_COLORS).length;
    return USER_BADGE_COLORS[colorIndex as keyof typeof USER_BADGE_COLORS];
  });

  return {
    userInitials,
    themeColor
  };
};
